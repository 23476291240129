import { Injectable } from '@angular/core';
import { CommonFunction } from '../common/common-function';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  userRight?: string
}

export interface Menu {
  label: string;
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  icon: string;
  badge?: BadgeItem[];
  userRight?: string,
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    label: 'Dashboard',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'empty_dashboard'
  },
  {
    label: 'Inventory<br/>Manager',
    state: 'inventory-manager',
    short_label: 'IM',
    name: 'Inventory<br/>Manager',
    icon: 'inventory',
    children: [
      {
        state: './',
        name: 'All<br/>Inventory',
        icon: 'inventory'
      },
      {
        state: 'create-inventory',
        name: 'Create<br/>Inventory',
        icon: 'contract_edit'
      },
      {
        state: 'stock-adjustment',
        name: 'Stock<br/>Adjustment',
        icon: 'inventory_2'
      },
      {
        state: 'view-stock',
        name: 'View<br/>Stock Card',
        icon: 'tv_options_edit_channels'
      }
    ]
  },
  {
    label: 'Project<br/>Manager',
    state: 'project-manager',
    short_label: 'IM',
    name: 'Project<br/>Manager',
    icon: 'apartment',
    children: [
      {
        state: './',
        name: 'All<br/>Project List',
        icon: 'apartment'
      },
      {
        state: 'projects-create',
        name: 'Register<br/>Project',
        icon: 'domain_add'
      },
      {
        state: 'all-wing',
        name: 'Wing<br/>Manager',
        icon: 'location_city'
      },
      {
        state: 'all-floor',
        name: 'Floor<br/>Manager',
        icon: 'corporate_fare'
      },
      {
        state: 'all-flat',
        name: 'Flat<br/>Manager',
        icon: 'meeting_room'
      }
    ]
  },
  {
    label: 'Supplier<br/>Manager',
    state: 'supplier-manager',
    short_label: 'IM',
    name: 'Supplier<br/>Manager',
    icon: 'person_check',
    children: [
      {
        state: './',
        name: 'All<br/>Suppliers',
        icon: 'demography'
      },
      {
        state: 'create-supplier',
        name: 'Create<br/>Supplier',
        icon: 'group_add'
      }
    ]
  },
  {
    label: 'Material Request<br/>Manager',
    state: 'material-request',
    short_label: 'IM',
    name: 'Material<br/>Request',
    icon: 'garden_cart',
    children: [
      {
        state: './',
        name: 'All<br/>Request',
        icon: 'garden_cart'
      }
    ]
  },
  {
    label: 'Order<br/>Manager',
    state: 'order-manager',
    short_label: 'IM',
    name: 'Order<br/>Manager',
    icon: 'shopping_cart_checkout',
    children: [
      {
        state: './',
        name: 'All<br/>Orders',
        icon: 'shopping_cart_checkout'
      },
      {
        state: 'create-order',
        name: 'Create<br/>Order',
        icon: 'deployed_code'
      }
    ]
  },
  {
    label: 'Purchase<br/>Manager',
    state: 'grn-manager',
    short_label: 'IM',
    name: 'Purchase<br/>Manager',
    icon: 'shopping_bag',
    children: [
      {
        state: './',
        name: 'GRN<br/>List',
        icon: 'format_list_bulleted'
      },
      {
        state: 'create-grn',
        name: 'Raised<br/>GRN',
        icon: 'videogame_asset'
      },
      {
        state: 'invoice',
        name: 'Post<br/>Invoice',
        icon: 'inventory'
      },
      {
        state: 'statement',
        name: 'Supplier<br/>Statement',
        icon: 'summarize'
      },
      {
        state: 'payment-summary',
        name: 'Payment<br/>Summary',
        icon: 'shopping_cart'
      }
    ]
  },
  {
    label: 'Asset<br/>Manager',
    state: 'asset-manager',
    short_label: 'IM',
    name: 'Asset<br/>Manager',
    icon: 'videogame_asset',
    children: [
      {
        state: './',
        name: 'Asset<br/>List',
        icon: 'videogame_asset'
      },
      {
        state: 'stockToAsset',
        name: 'Stock<br/>To Asset',
        icon: 'move_up'
      }
    ]
  },
  {
    label: 'Banking<br/>Manager',
    state: 'banking-manager',
    short_label: 'IM',
    name: 'Banking<br/>Manager',
    icon: 'account_balance',
    children: [
      {
        state: './',
        name: 'All<br/>Cheque Book',
        icon: 'checkbook'
      },
      {
        state: 'petty-cash',
        name: 'Petty<br/>CashBook',
        icon: 'payments',
      },
    ]
  },
  {
    label: 'Nominal<br/>Manager',
    state: 'nominal-manager',
    short_label: 'IM',
    name: 'Nominal<br/>Manager',
    icon: 'account_balance_wallet',
    children: [      
      {
        state: './',
        name: 'Nominal<br/>Account',
        icon: 'manage_accounts',
      },
      {
        state: 'nominal-ledger',
        name: 'Nominal<br/>Ledger',
        icon: 'supervisor_account',
      },
      {
        state: 'nominal-account-subGroup',
        name: 'Nominal<br/>Sub Group',
        icon: 'group',
      }
    ]
  },
  {
    label: 'HR<br/>Manager',
    state: 'hr-manager',
    short_label: 'IM',
    name: 'HR<br/>Manager',
    icon: 'badge',
    children: [
      {
        state: './',
        name: 'View All<br/>Employees',
        icon: 'groups',
      },
      {
        state: 'create-employee',
        name: 'Create<br/>Employee',
        icon: 'person_add',
      },
      {
        state: 'holiday-manager',
        name: 'Holiday<br/>Manager',
        icon: 'holiday_village',
      },
      {
        state: 'leave-list',
        name: 'Leave\'s<br/>List',
        icon: 'calendar_clock',
      },
      {
        state: 'attendance-manager',
        name: 'Attendance<br/>Manager',
        icon: 'perm_contact_calendar',
      }
    ]
  },
  {
    label: 'CRM<br/>Manager',
    state: 'crm-manager',
    short_label: 'IM',
    name: 'CRM<br/>Manager',
    icon: 'leaderboard',
    children: [
      {
        state: './',
        name: 'Enquiry\'s<br/>List',
        icon: 'search'
      },
      {
        state: 'quot-list',
        name: 'Quotation<br/>List',
        icon: 'receipt_long'
      }
    ]
  },
  {
    label: 'Sales Manager',
    state: 'sales-manager',
    short_label: 'D',
    name: 'Sales<br/>Manager',
    icon: 'real_estate_agent',
    children: [
      {
        state: './',
        name: 'Booking<br/>List',
        icon: 'real_estate_agent'
      },
      {
        state: 'new-booking',
        name: 'New<br/>Booking',
        icon: 'point_of_sale'
      },
      {
        state: 'pending-payment',
        name: 'Pending<br/>Payment',
        icon: 'credit_card_clock'
      },
    ]
  },
  {
    label: 'Report<br/>Manager',
    state: 'report-manager',
    short_label: 'IM',
    name: 'Report<br/>Manager',
    icon: 'folder_open',
    children: [
      {
        state: './',
        name: 'Trail<br/>Balance',
        icon: 'menu_book'
      },
      {
        state: 'profit-loss',
        name: 'Profit &<br/>Loss Sheet',
        icon: 'data_exploration'
      },
      {
        state: 'balance-sheet',
        name: 'Balance<br/>Sheet',
        icon: 'heap_snapshot_large'
      }
    ]
  },
  {
    label: 'Company<br/>Setting',
    state: 'setting-manager',
    name: 'Company<br/>Setting',
    icon: 'settings',
    children: [
      {
        state: './',
        name: 'Code<br/>Setup',
        icon: 'settings'
      },
      {
        state: 'sales-setup',
        name: 'Sales<br/>Setup',
        icon: 'real_estate_agent'
      },
      {
        state: 'hr-setup',
        name: 'HR<br/>Setup',
        icon: 'medical_information'
      }
    ]
  },
];

const MENUSUPERCOMPANYITEMS = [
  {
    label: '',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'home'
  },
  {
    label: 'Plan\'s Manager',
    state: 'plan-manager',
    short_label: 'PC',
    name: 'Package<br/>Manager',
    icon: 'package_2',
    children: [
      {
        state: 'create-inventory',
        name: 'All Plan'
      },
      {
        state: 'list',
        name: 'Create Plan'
      }
    ]
  },
  {
    label: 'Company Manager',
    state: 'company-registration',
    short_label: 'CR',
    name: 'Company<br/>Manager',
    icon: 'store',
    userRight: 'companyRegistration',
    children: [
      {
        state: 'create-inventory',
        name: 'Company<br/>Registration'
      },
      {
        state: 'list',
        name: 'All<br/>Company'
      }
    ]
  },
  {
    label: 'Access Manager',
    state: 'sup-emp',
    short_label: 'PT',
    name: 'Access<br/>Manager',
    icon: 'fingerprint',
    children: [
      {
        state: 'create-inventory',
        name: 'Company<br/>Rights'
      },
      {
        state: 'list',
        name: 'All<br/>Company'
      }
    ]
  },
  {
    label: 'Nominal Manager',
    state: 'nominal-manager',
    short_label: 'PT',
    name: 'Nominal<br/>Manager',
    icon: 'assured_workload',
    children: [
      {
        state: 'create-inventory',
        name: 'Nominal Account'
      }
    ]
  },
  {
    label: 'Licence Manager',
    state: 'licence-manager',
    short_label: 'LM',
    name: 'Licence<br/>Manager',
    icon: 'license',
    children: [
      {
        state: 'create-inventory',
        name: 'Nominal Account'
      }
    ]
  },
  {
    label: 'State Manager',
    state: 'state-manager',
    short_label: 'LM',
    name: 'State<br/>Manager',
    icon: 'location_city',
  }
];

const MenuForSites = [
  {
    label: 'Dashboard',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'empty_dashboard'
  },
  {
    label: 'Stock Manager',
    state: 'stock-manager',
    short_label: 'D',
    name: 'Stock<br/>Manager',
    icon: 'inventory_2',
    children: [
      {
        state: './',
        name: 'Stock<br/>Card',
        icon: 'tv_options_edit_channels'
      },
      {
        state: 'stock-adjustment',
        name: 'Daily<br/>Used Stock',
        icon: 'inventory_2'
      }
    ]
  },
  {
    label: 'Material Manager',
    state: 'material-request',
    short_label: 'D',
    name: 'Material<br/>Manager',
    icon: 'garden_cart',
    children: [
      {
        state: './',
        name: 'All Material<br/>Request',
        icon: 'garden_cart'
      },
      {
        state: 'raised-request',
        name: 'Raised<br/>Request',
        icon: 'add_circle'
      }
    ]
  },
  {
    label: 'Orders Manager',
    state: 'order-manager',
    short_label: 'D',
    name: 'Orders<br/>Manager',
    icon: 'shopping_cart_checkout',
    children: [
      {
        state: './',
        name: 'Orders<br/>List',
        icon: 'shopping_cart_checkout'
      }
    ]
  },
  {
    label: 'Purchase Manager',
    state: 'grn-manager',
    short_label: 'D',
    name: 'Purchase<br/>Manager',
    icon: 'videogame_asset',
    children: [
      {
        state: './',
        name: 'All<br/>GRNs',
        icon: 'format_list_bulleted'
      },
      {
        state: 'create-grn',
        name: 'Raised<br/>GRN(Qty)',
        icon: 'videogame_asset'
      },
      {
        state: 'invoice',
        name: 'Post<br/>Invoice',
        icon: 'inventory'
      }
    ]
  },
  {
    label: 'CRM Manager',
    state: 'crm-manager',
    short_label: 'D',
    name: 'CRM<br/>Manager',
    icon: 'leaderboard',
    children: [
      {
        state: './',
        name: 'All<br/>Enquiry',
        icon: 'support_agent'
      },
      {
        state: 'raised-enquiry',
        name: 'Raised<br/>Enquiry',
        icon: 'add_circle'
      },
      {
        state: 'quot-list',
        name: 'Quotation<br/>List',
        icon: 'receipt_long'
      }
    ]
  },
  {
    label: 'Booking Manager',
    state: 'sales-manager',
    short_label: 'D',
    name: 'Sales<br/>Manager',
    icon: 'real_estate_agent',
    children: [
      {
        state: './',
        name: 'Booking<br/>List',
        icon: 'real_estate_agent'
      }
    ]
  }
]

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('info')));

    if (data.userRole == 'Company') {
      return MENUITEMS;
    } else if (data.userRole === "SuperCompany") {
      return MENUSUPERCOMPANYITEMS;
    } else if (data.userRole === "ConstructSites") {
      return MenuForSites;
    }
  }

  getSubMenu(state: string): ChildrenItems[] {
    let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('info')));
    var subMenuData:ChildrenItems[] = [];

    let arr = this.getAll();
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.state === state) {
        subMenuData = element.children
        break;
      }
    }

    return subMenuData
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
