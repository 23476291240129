<div class="row">
    <div class="col-sm-12">
        <!-- Hover table card start -->
        <app-card [title]="''" [classHeader]="true" [blockClass]="'table-border-style'">
            <form (ngSubmit)="submitNewPlan()" [formGroup]="planForm">
                <div class="row">
                    <div class="form-floating col-lg-3 col-md-3 col-sm-12">
                        <label class="control-label">Plan Name</label>
                        <input type="text" class="form-control rounded" formControlName="packageName">
                    </div>

                    <div class="form-group col-lg-3 col-md-3 col-sm-12">
                        <label for="exampleFormControlInput1" class="control-label">No of Users</label>
                        <input type="number" min="1" class="form-control rounded" id="exampleFormControlInput1"
                         formControlName="noOfUser">
                    </div>

                    <div class="form-group col-lg-3 col-md-3 col-sm-12">
                        <label for="exampleFormControlInput1" class="control-label">Days</label>
                        <input type="number" class="form-control" min="1" id="exampleFormControlInput1" 
                            formControlName="noOfDays">
                    </div>

                    <div class="form-group col-lg-3 col-md-3 col-sm-12">
                        <label for="exampleFormControlInput1" class="control-label">Amount</label>
                        <input type="number" class="form-control" min="1" id="exampleFormControlInput1"
                            formControlName="packageAmount">
                    </div>

                    <div class="form-group col-12">
                        <label for="exampleFormControlInput1" class="control-label">Plan Details</label>
                        <textarea class="form-control rounded" aria-label="Default select example" rows="4"
                            formControlName="planDetails"></textarea>
                    </div>
                </div>
                <div class="col-12">
                    <ngb-alert [dismissible]="true" [animation]="true" type='danger' class="float-left"
                        *ngIf="errorMessage && errorMessage.length > 0">
                        {{errorMessage}}
                    </ngb-alert>

                    <button type="submit" class="btn btn-dark float-right rounded mb-5 float-right">Add
                        Add Package</button>
                </div>
            </form>
        </app-card>
        <!-- Hover table card end -->
    </div>
</div>