import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../pages/auth/services/auth.service';
import { CommonFunction } from '../shared/common/common-function';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private router: Router, private accountService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.accountService.isUserAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/auth'], { replaceUrl: true });
      return false;
    }
  }

}
