import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyService } from '../services/company.service';
import { ToastrService } from 'ngx-toastr';
import { StateManagerService } from '../../state-manager/service/state-manager.service';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrl: './company-registration.component.scss'
})
export class CompanyRegistrationComponent {
  isGST = 1;
  errorMessage = "";
  successMessage = "";
  stateList = [];

  constructor(private companyService: CompanyService,
    private stateService: StateManagerService,
    private toastService: ToastrService) { }

  registrationForm = new FormGroup({
    reg_name: new FormControl(""),
    reg_no: new FormControl(""),
    primary_conatct_no: new FormControl(""),
    primary_email_id: new FormControl(""),
    contact_person_name: new FormControl(""),
    company_type: new FormControl("product"),
    company_state: new FormControl(),
    reg_address: new FormControl(''),
    isGST: new FormControl(1),
    gstNumber: new FormControl(''),
    gstAddress: new FormControl(''),
    display_name: new FormControl(''),
    support_email_id: new FormControl(''),
    support_contact_no: new FormControl(''),
    display_address: new FormControl('')
  });

  ngOnInit(): void {
    this.getStateList();
  }

  isGSTChange() {
    this.isGST = this.registrationForm.get("isGST").value;
  }

  getStateList() {
    this.stateService.getServiceList().subscribe((res: any) => {
      this.stateList = res.data;
    }, err => this.toastService.error(err.message));
  }

  submitRegistrationForm() {
    this.errorMessage = "";
    this.companyService.createCompany(this.registrationForm.value).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.registrationForm.reset();
        this.toastService.success(res.message)
      } else {
        this.errorMessage = res.message
        this.toastService.warning(res.message)
      }
    }, err => this.toastService.error(err.message));
  }
}
