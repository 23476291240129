import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, SPINNER } from 'ngx-ui-loader';
import { provideToastr, ToastrModule } from 'ngx-toastr';
import { CompanyRegistrationComponent } from './pages/company-manager/company-registration/company-registration.component';
import { CreatePlanComponent } from './pages/plan-manager/create-plan/create-plan.component';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "fgsType": SPINNER.threeStrings,
  "fgsSize": 90,
  "delay": 0,
  "pbThickness": 3,
  "fastFadeOut": true,
  "overlayColor": "rgba(40, 40, 40, 0.95)",
  "minTime": 300
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    CompanyRegistrationComponent,
    CreatePlanComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }), NgbModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    MenuItems,
    provideAnimations(),
    provideToastr(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
